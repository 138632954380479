import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { action, AppState, store, SetAbrirAguardeAction } from './ConfigState'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
    opacity: '0.5'
  }
}))

export default function Aguarde () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const AbrirAguarde = useSelector(state => state.configuracoes.AbrirAguarde)
  const [Open, setOpen] = React.useState(false)
  const handleClose = () => {
    dispatch(SetAbrirAguardeAction(false))
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={AbrirAguarde}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}
