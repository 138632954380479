import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Avatar } from '@material-ui/core'
import aluno from './Avatar.png'
import Typography from '@material-ui/core/Typography'
import { yellow } from '@material-ui/core/colors'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import QuestaoParaEdicao from './QuestaoEdicao'
import { useSelector, useDispatch } from 'react-redux'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import Aguarde from './backdrop'
import Aviso from './aviso'
import {
  action,
  AppState,
  store,
  SetOpcaoSem1Action,
  SetOpcaoSem2Action,
  SetAbrirEdicaoAction,
  SetAtualizarComparativoAction,
  SetAbrirAguardeAction,
  SetAvisoAction,
  SetErroGraveAction
} from './ConfigState'
import { servapi } from './servidorapi'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '1%',
    backgroundColor: '#ECECEC'
  },

  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 0,
    width: '80%'
  },
  tabela: {
    padding: theme.spacing(2)
  },
  coluna: {
    color: theme.palette.text.secondary
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  topico: {
    color: '#E24A3B',
    fontWeight: 'fontWeightBold',
    fontSize: 20
  },
  questao: {
    color: '#2E92D9',
    fontSize: 15
  },
  legenda: {
    fontSize: 9,
    color: 'black'
  },
  semestre: {
    fontWeight: 'fontWeightBold',
    fontSize: 12
  },
  resposta: {
    fontWeight: 'fontWeightBold',
    fontSize: 12
  },
  nome: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    editbtn: {}
  },

  habilitacao: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },

  divedicao: {
    marginTop: 200
  }
}))

export default function Comparativo (props) {
  const [IdFormularioSem1, setIdFormularioSem1] = useState(0)
  const [IdFormularioSem2, setIdFormularioSem2] = useState(0)
  const [IdTopicoSem1, setIdTopicoSem1] = useState(0)
  const [IdTopicoSem2, setIdTopicoSem2] = useState(0)
  const [IdQuestaoSem1, setIdQuestaoSem1] = useState(0)
  const [IdQuestaoSem2, setIdQuestaoSem2] = useState(0)
  const [IdOpcaoSem1, setIdOpcaoSem1] = useState(0)
  const [IdOpcaoSem2, setIdOpcaoSem2] = useState(0)
  const [RespostaAbreviadaSem1, setRespostaAbreviadaSem1] = useState('')
  const [RespostaAbreviadaSem2, setRespostaAbreviadaSem2] = useState('')
  const [Questao, setQuestao] = useState('')
  const [Opcao, setOpcao] = useState([])
  const [open, setOpen] = React.useState(false)

  const OpcaoSem1 = useSelector(state => state.configuracoes.OpcaoSem1)
  const OpcaoSem2 = useSelector(state => state.configuracoes.OpcaoSem2)
  const AbrirEdicao = useSelector(state => state.configuracoes.AbrirEdicao)
  const AtualizarComparativo = useSelector(
    state => state.configuracoes.AtualizarComparativo
  )
  const ErroGrave = useSelector(state => state.configuracoes.ErroGrave)
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker()
  function Edicao (
    idformulariosem1,
    idformulariosem2,
    idtopicosem1,
    idtopicosem2,
    idquestaosem1,
    idquestaosem2,
    questao,
    opcoes,
    respostaabreviadasem1,
    respostaabreviadasem2
  ) {
    setIdFormularioSem1(idformulariosem1)
    setIdFormularioSem2(idformulariosem2)
    setIdTopicoSem1(idtopicosem1)
    setIdTopicoSem2(idtopicosem2)
    setIdQuestaoSem1(idquestaosem1)
    setIdQuestaoSem2(idquestaosem2)
    setQuestao(questao)
    setRespostaAbreviadaSem1(respostaabreviadasem1)
    setRespostaAbreviadaSem2(respostaabreviadasem2)
    setOpcao(opcoes)
    dispatch(SetAbrirEdicaoAction(true))
  }

  const EditaResposta = props => {
    console.log('HabilitaEdicao ' + JSON.stringify(props.HabilitarEdicao))
    if (props.HabilitarEdicao) {
      return (
        <IconButton
          aria-label='delete'
          className={classes.editbtn}
          size='small'
          onClick={() =>
            Edicao(
              props.item.idFormularioSem1,
              props.item.idFormularioSem2,
              props.item.idTopicoSem1,
              props.item.idTopicoSem2,
              props.item.idQuestaoSem1,
              props.item.idQuestaoSem2,
              props.item.descricao,
              props.item.opcoes,
              props.item.respostaSemestre1,
              props.item.respostaSemestre2
            )
          }
        >
          <CreateIcon fontSize='inherit' />
        </IconButton>
      )
    } else {
      return null
    }
  }
  function Topicos (props) {
    if (Data.topicos) {
      return Data.topicos.map((item, index) => (
        <React.Fragment>
          <TableRow>
            <TableCell
              colSpan={7}
              style={{ borderBottom: 'none' }}
              className={classes.topico}
            >
              {item.descricao}
            </TableCell>
          </TableRow>
          {item.questoes.map((item, index) => (
            <React.Fragment>
              <TableRow>
                <TableCell style={{ borderBottom: 'none' }}></TableCell>
                <TableCell colSpan={3} align='left' className={classes.questao}>
                  {item.descricao}
                </TableCell>
                <TableCell align='right'>
                  <EditaResposta
                    HabilitarEdicao={props.HabilitarEdicao}
                    item={item}
                  />
                </TableCell>
                <TableCell align='right' className={classes.resposta}>
                  {item.respostaSemestre1}
                </TableCell>
                <TableCell align='right' className={classes.resposta}>
                  {item.respostaSemestre2}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}

          <QuestaoParaEdicao
            Opcao={Opcao}
            RespostaSem1={RespostaAbreviadaSem1}
            RespostaSem2={RespostaAbreviadaSem2}
            IdFormularioSem1={IdFormularioSem1}
            IdFormularioSem2={IdFormularioSem2}
            IdTopicoSem1={IdTopicoSem1}
            IdTopicoSem2={IdTopicoSem2}
            Questao={Questao}
            IdQuestaoSem1={IdQuestaoSem1}
            IdQuestaoSem2={IdQuestaoSem2}
            Matricula={props.Matricula}
          />
        </React.Fragment>
      ))
    } else {
      return null
    }
  }

  const [Loading, setLoading] = useState(false)
  const [Data, setData] = useState(false)
  const classes = useStyles()
  const search = useLocation().search
  const Matricula = new URLSearchParams(search).get('Matricula')
  const CodHabilitacao = new URLSearchParams(search).get('CodHabilitacao')
  const Ano = new URLSearchParams(search).get('Ano')
  const CodUsuario = new URLSearchParams(search).get('CodUsuario')
  console.log('Matricula ' + Matricula)
  console.log(props)

  useEffect(() => {
    dispatch(SetErroGraveAction(false))
  }, [])
  useEffect(() => {
    setLoading(true)
    const apiUrl =
      `https://` +
      servapi +
      `/api/EdInfantil?Ano=` +
      Ano +
      `&Matricula=` +
      Matricula +
      `&CodHab=` +
      CodHabilitacao +
      '&CodUsuario=' +
      CodUsuario
    trackPromise(
      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then(res => res.json())
        .then(data => {
          console.log('data ??? ' + data)
          setData(data)
          setLoading()
        })
        .catch(function (error) {
          console.log('catch error' + error)
          dispatch(
            SetAvisoAction(
              'Ops! Não foi possível recuperar os dados.Tente mais tarde ou entre em contato com a instituição.'
            )
          )
        })
    )
  }, [Matricula])

  useEffect(() => {
    console.log('atualizar comp ' + AtualizarComparativo)
    if (AtualizarComparativo) {
      const apiUrl =
        `https://` +
        servapi +
        `/api/EdInfantil?Ano=` +
        Ano +
        `&Matricula=` +
        Matricula +
        `&CodHab=` +
        CodHabilitacao +
        '&CodUsuario=' +
        CodUsuario
      trackPromise(
        fetch(apiUrl)
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText)
            }
            return response
          })
          .then(res => res.json())
          .then(data => {
            console.log('data !!! ' + data)
            setData(data)
            dispatch(SetAtualizarComparativoAction(false))
          })
          .catch(function (error) {
            console.log('catch error' + error)
            dispatch(
              SetAvisoAction(
                'Ops! Não foi possível recuperar os dados.Tente mais tarde ou entre em contato com a instituição.'
              )
            )
          })
      )
    }
  }, [AtualizarComparativo])

  useEffect(() => {
    if (promiseInProgress) {
      dispatch(SetAbrirAguardeAction(true))
      dispatch(SetAvisoAction('Carregando os dados...'))
    } else {
      dispatch(SetAbrirAguardeAction(false))
    }
  }, [promiseInProgress])
  if (Data.topicos && !ErroGrave) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Paper elevation={3} className={classes.paper}>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={2}
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Avatar
                    alt='aluno'
                    src={'data:image/jpg;base64,' + Data.foto}
                    className={classes.large}
                  />
                </Grid>
                <Grid item sm={10} xs={12} style={{ display: 'flex' }}>
                  <Grid container spacing={0} flex-direction='column'>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', alignItems: 'flex-end' }}
                      className={classes.nome}
                    >
                      <Typography>{Data.aluno}</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', alignItems: 'flex-start' }}
                      className={classes.habilitacao}
                    >
                      <Typography>{Data.habilitacao}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  item
                  sm={6}
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Typography className={classes.legenda}>Legenda:</Typography>
                </Grid>

                <Grid
                  item
                  sm={1}
                  xs={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Typography className={classes.legenda}>S - SIM</Typography>
                </Grid>
                <Grid
                  item
                  sm={1}
                  xs={2}
                  style={{
                    display: 'flex',

                    justifyContent: 'flex-start'
                  }}
                >
                  <Typography className={classes.legenda}>N - NÃO</Typography>
                </Grid>
                <Grid
                  item
                  sm={1}
                  xs={3}
                  style={{
                    display: 'flex',

                    justifyContent: 'flex-start'
                  }}
                >
                  <Typography className={classes.legenda}>
                    AS - ÀS VEZES
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  item
                  sm={6}
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                ></Grid>
                <Grid
                  item
                  sm={2}
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Typography className={classes.legenda}>
                    ED - EM DESENVOLVIMENTO
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Typography className={classes.legenda}>
                    NT - NÃO TRABALHADO
                  </Typography>
                </Grid>
              </Grid>
              <TableContainer className={classes.tabela}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: 'none' }}
                        colSpan={5}
                      ></TableCell>
                      <TableCell
                        style={{ borderBottom: 'none' }}
                        className={classes.semestre}
                        align='right'
                        colSpan={1}
                      >
                        1° Semestre
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: 'none' }}
                        className={classes.semestre}
                        align='right'
                        colSpan={1}
                      >
                        2° Semestre
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Topicos
                      Matricula={Matricula}
                      HabilitarEdicao={Data.habilitarEdicao}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <Aguarde />
        <Aviso />
      </React.Fragment>
    )
  }
}
