import logo from './logo.svg'
import './App.css'
import Pagina from './Pagina'
import Comparativo from './Comparativo'
import { BrowserRouter } from 'react-router-dom'
import { store } from './ConfigState'
import { Provider } from 'react-redux'
function App () {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Comparativo />
      </BrowserRouter>
    </Provider>
  )
}

export default App
