import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Avatar } from '@material-ui/core'
import aluno from './Avatar.png'
import Typography from '@material-ui/core/Typography'
import { yellow } from '@material-ui/core/colors'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '1%'
  },

  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 0,
    width: '80%'
  },
  tabela: {
    padding: theme.spacing(2)
  },
  coluna: {
    color: theme.palette.text.secondary
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  topico: {
    color: '#E24A3B',
    fontWeight: 'fontWeightBold',
    fontSize: 20
  },
  questao: {
    color: '#2E92D9',
    fontSize: 15
  },
  legenda: {
    fontWeight: 'fontWeightBold',
    color: theme.palette.text.secondary,
    fontSize: 10
  },
  semestre: {
    fontWeight: 'fontWeightBold',
    fontSize: 12
  },
  resposta:
  {
    fontWeight: 'fontWeightBold',
    fontSize: 12

  }
}))
export default function Pagina () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={2}>
              <Grid
                item
                sm={2}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Avatar alt='aluno' src={aluno} className={classes.large} />
              </Grid>
              <Grid
                item
                sm={10}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <Grid container spacing={0} flex-direction='column'>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <Typography>Vinicius da Silva Domingues</Typography>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'flex-start' }}
                  >
                    <Typography>1° Ano</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TableContainer className={classes.tabela}>
              <Table aria-label='spanning table'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.coluna}
                      style={{
                        fontSize: 12,
                        align: 'left',
                        fontWeight: 'fontWeightBold'
                      }}
                    >
                      Legenda
                    </TableCell>

                    <TableCell
                      style={{
                        align: 'center'
                      }}
                      className={classes.legenda}
                    >
                      S - SIM
                    </TableCell>
                    <TableCell
                      style={{
                       
                        align: 'center'
                      }}
                      className={classes.legenda}
                    >
                      N - NÃO
                    </TableCell>
                    <TableCell
                      style={{
                        
                        align: 'center'
                      }}
                      className={classes.legenda}
                    >
                      AV - ÁS VEZES
                    </TableCell>
                    <TableCell
                      style={{
                     
                        align: 'center'
                      }}
                      className={classes.legenda}
                    >
                      A - ADQUIRIDO
                    </TableCell>
                    <TableCell
                      style={{
                        
                        align: 'center'
                      }}
                      className={classes.legenda}
                    >
                      NA - NÃO ADQUIRIDO
                    </TableCell>
                    <TableCell
                      style={{
                      
                        align: 'center'
                      }}
                      className={classes.legenda}
                    >
                      ED - EM DESENVOLVIMENTO
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={4}
                    ></TableCell>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      className={classes.semestre}
                      align='right'
                    >
                      1° Semestre
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      className={classes.semestre}
                      align='right'
                    >
                      2° Semestre
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      style={{ borderBottom: 'none' }}
                      className={classes.topico}
                    >
                      Desenvolvimento
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      style={{ borderBottom: 'none' }}
                      align='left'
                      className={classes.questao}
                    >
                      1)Psicomotor
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ borderBottom: 'none' }}></TableCell>

                    <TableCell
                      colSpan={3}
                      align='left'
                      className={classes.coluna}
                    >
                      Sustenta a cabeça
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      ED
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      ED
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: 'none' }}></TableCell>
                    <TableCell
                      colSpan={3}
                      align='left'
                      className={classes.coluna}
                    >
                      Descobre a mão no seu campo visual
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      ED
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      ED
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: 'none' }}></TableCell>
                    <TableCell
                      colSpan={3}
                      align='left'
                      className={classes.coluna}
                    >
                      Vira-se na direção de um estímulo auditivo
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      AV
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      A
                    </TableCell>
                  </TableRow>
                    <TableRow>
                    <TableCell style={{ borderBottom: 'none' }}></TableCell>
                    <TableCell
                      colSpan={3}
                      align='left'
                      className={classes.coluna}
                    >
                      desloca-se na direção dos objetos que quer pegar 
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      AV
                    </TableCell>
                    <TableCell align='right' className={classes.resposta}>
                      A
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

