import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Typography from '@material-ui/core/Typography'
import { useSelector, useDispatch } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import Aguarde from './backdrop'
import Aviso from './aviso'
import {
  action,
  AppState,
  store,
  SetOpcaoSem1Action,
  SetOpcaoSem2Action,
  SetAbrirEdicaoAction,
  SetAbrirAguardeAction,
  SetAtualizarComparativoAction,
  SetErroGraveAction,
  SetAvisoAction
} from './ConfigState'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '1%',
    backgroundColor: '#ECECEC'
  },

  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 0,
    width: '80%'
  },
  tabela: {
    padding: theme.spacing(2)
  },
  coluna: {
    color: theme.palette.text.secondary
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  topico: {
    color: '#E24A3B',
    fontWeight: 'fontWeightBold',
    fontSize: 20
  },
  questao: {
    color: '#2E92D9',
    fontSize: 15
  },
  legenda: {
    fontSize: 9,
    color: 'black'
  },
  semestre: {
    fontWeight: 'fontWeightBold',
    fontSize: 12
  },
  resposta: {
    fontWeight: 'fontWeightBold',
    fontSize: 12
  },
  nome: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    editbtn: {}
  },

  habilitacao: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },

  divedicao: {
    marginTop: 150
  },

  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))

export default function QuestaoParaEdicao (props) {
  const [ValueOpcao, setValueOpcao] = React.useState('')
  const AbrirEdicao = useSelector(state => state.configuracoes.AbrirEdicao)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { promiseInProgress } = usePromiseTracker()
  const [ValueOpcaoSem1, setValueOpcaoSem1] = React.useState('')

  const handleChangeOpcaoSem1 = event => {
    setValueOpcaoSem1(event.target.value)
  }
  const handleChangeOpcaoSem2 = event => {
    setValueOpcaoSem2(event.target.value)
  }

  const [ValueOpcaoSem2, setValueOpcaoSem2] = React.useState('')

  const handleCloseEdicao = () => {
    dispatch(SetAbrirEdicaoAction(false))
  }

  const handleSalvar = () => {
    var url = 'https://localhost:44365/api/EdInfantil'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IDFormularioSem1: props.IdFormularioSem1,
        IDFormularioSem2: props.IdFormularioSem2,
        IDTopicoSem1: props.IdTopicoSem1,
        IDTopicoSem2: props.IdTopicoSem2,
        IDQuestaoSem1: props.IdQuestaoSem1,
        IDQuestaoSem2: props.IdQuestaoSem2,
        Matricula: props.Matricula,
        OpcaoAbreviadaSem1: ValueOpcaoSem1,
        OpcaoAbreviadaSem2: ValueOpcaoSem2
      })
    }

    trackPromise(
      fetch(url, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          dispatch(SetAtualizarComparativoAction(true))
        }).then(dispatch(SetAbrirEdicaoAction(false)))
        .catch(function (error) {
          dispatch(SetErroGraveAction(true))
          dispatch(
            SetAvisoAction(
              'Ops! Não foi possível salvar os dados.Tente mais tarde ou entre em contato com a instituição.'
            )
          )
        })
    )
  }

  useEffect(() => {
    console.log('IdFormularioSem1 ' + props.IdFormularioSem1)
    console.log('IdFormularioSem2 ' + props.IdFormularioSem2)
    setValueOpcaoSem1(props.RespostaSem1)
    setValueOpcaoSem2(props.RespostaSem2)
    dispatch(SetErroGraveAction(false))
  }, [])

  // useEffect(() => {
  //   if (promiseInProgress) {
  //     dispatch(SetAbrirAguardeAction(true))
  //   } else {
  //     dispatch(SetAbrirAguardeAction(false))
  //   }
  // }, [promiseInProgress])

  return (
    <Dialog fullScreen open={AbrirEdicao} onClose={handleCloseEdicao}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Grid
            container
            spacing={2}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={1}>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleCloseEdicao}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={11}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button autoFocus color='inherit' onClick={handleSalvar}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.divedicao}>
        <Grid item xs={1}></Grid>
        <Grid
          container
          spacing={2}
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Grid
            item
            xs={11}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography gutterBottom variant='h6'>
              {props.Questao}
            </Typography>
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={11}>
            <Grid
              container
              spacing={2}
              direction='row'
              justifyContent='center'
              alignItems='flex-start'
            >
              <Grid item xs={2}></Grid>

              <Grid item xs={6}>
                <Typography variant='h6' gutterBottom>
                  1° Semestre
                </Typography>
              </Grid>

              <Grid item xs={4}></Grid>

              {props.Opcao.map((item, index) => (
                <Grid item key={index}>
                  <RadioGroup
                    aria-label='opcao'
                    name={'opcao' + index}
                    value={ValueOpcaoSem1}
                    onChange={handleChangeOpcaoSem1}
                  >
                    <FormControlLabel
                      value={item.opcaoAbreviada}
                      control={<Radio />}
                      label={item.opcaoDescr}
                    />
                  </RadioGroup>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              spacing={2}
              direction='row'
              justifyContent='center'
              alignItems='flex-start'
              style={{ marginTop: 30 }}
            >
              <Grid item xs={2}></Grid>

              <Grid item xs={6}>
                <Typography variant='h6' gutterBottom>
                  2° Semestre
                </Typography>
              </Grid>

              <Grid item xs={4}></Grid>

              {props.Opcao.map((item, index) => (
                <Grid item>
                  <RadioGroup
                    aria-label='opcao'
                    name={'opcao' + index}
                    value={ValueOpcaoSem2}
                    onChange={handleChangeOpcaoSem2}
                  >
                    <FormControlLabel
                      value={item.opcaoAbreviada}
                      control={<Radio />}
                      label={item.opcaoDescr}
                    />
                  </RadioGroup>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* <Aguarde /> */}
    </Dialog>
  )
}
