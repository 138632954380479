import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useSelector, useDispatch } from 'react-redux'
import { action, AppState, store, SetAviso } from './ConfigState'
import logocel from './cellogo.png'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
      height: '100%'
    }
  },
  container: {
    marginTop: '10%',
    marginLeft: '5%',
    marginBottom: '10%'
  },
  logo: {
    width: '80px',
    margin: 10
  }
}))

export default function Aviso () {
  const classes = useStyles()
  const Aviso = useSelector(state => state.configuracoes.Aviso)
  return (
    <div className={classes.root}>
      <Grid
        className={classes.container}
        container
        spacing={2}
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      >
        <Grid item xs={2}></Grid>

        <Grid
          item
          xs={10}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            marginLeft: 0
          }}
        >
          <Typography variant='h6' gutterBottom>
            {Aviso}
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </div>
  )
}
