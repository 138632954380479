import { Store, createStore, combineReducers, applyMiddleware } from 'redux'

interface ConfigState {
  readonly OpcaoSem1: string
  readonly OpcaoSem2: string
  readonly AbrirEdicao: boolean
  readonly AbrirAguarde: boolean
  readonly AtualizarComparativo: boolean
  readonly Aviso: string
  readonly ErroGrave: boolean
}

export interface AppState {
  readonly configuracoes: ConfigState
}

const initialConfigState: ConfigState = {
  OpcaoSem1: '',
  OpcaoSem2: '',
  AbrirEdicao: false,
  AbrirAguarde: false,
  AtualizarComparativo: false,
  Aviso: '',
  ErroGrave: false
}

export const GETTINCONFIG = 'GettingConfig'
export const gettingConfigAction = () =>
  ({
    type: GETTINCONFIG
  } as const)

export const SETOPCAOSEM1 = 'OpcaoSem1'
export const SetOpcaoSem1Action = (opcaosem1: string) =>
  ({
    type: SETOPCAOSEM1,
    opcaosem1: opcaosem1
  } as const)

export const SETOPCAOSEM2 = 'OpcaoSem2'
export const SetOpcaoSem2Action = (opcaosem2: string) =>
  ({
    type: SETOPCAOSEM2,
    opcaosem2: opcaosem2
  } as const)

export const SETABRIREDICAO = 'AbrirEdicao'
export const SetAbrirEdicaoAction = (abriredicao: boolean) =>
  ({
    type: SETABRIREDICAO,
    abriredicao: abriredicao
  } as const)

export const SETABRIRAGUARDE = 'AbrirAguarde'
export const SetAbrirAguardeAction = (abriraguarde: boolean) =>
  ({
    type: SETABRIRAGUARDE,
    abriraguarde: abriraguarde
  } as const)

export const SETATUALIZARCOMPARATIVO = 'AtualizarComparativo'
export const SetAtualizarComparativoAction = (atualizarcomparativo: boolean) =>
  ({
    type: SETATUALIZARCOMPARATIVO,
    atualizarcomparativo: atualizarcomparativo
  } as const)

export const SETAVISO = 'Aviso'
export const SetAvisoAction = (aviso: string) =>
  ({
    type: SETAVISO,
    aviso: aviso
  } as const)

export const SETERROGRAVE = 'ErroGrave'
export const SetErroGraveAction = (errograve: boolean) =>
  ({
    type: SETERROGRAVE,
    errograve: errograve
  } as const)

type ConfigActions =
  | ReturnType<typeof gettingConfigAction>
  | ReturnType<typeof SetOpcaoSem1Action>
  | ReturnType<typeof SetOpcaoSem2Action>
  | ReturnType<typeof SetAbrirEdicaoAction>
  | ReturnType<typeof SetAbrirAguardeAction>
  | ReturnType<typeof SetAtualizarComparativoAction>
  | ReturnType<typeof SetAvisoAction>
  | ReturnType<typeof SetErroGraveAction>

const configReducer = (state = initialConfigState, action: ConfigActions) => {
  switch (action.type) {
    case GETTINCONFIG: {
      return {
        ...state
      }
    }
    case SETOPCAOSEM1: {
      return {
        ...state,
        OpcaoSem1: action.opcaosem1
      }
    }
    case SETOPCAOSEM2: {
      return {
        ...state,
        OpcaoSem2: action.opcaosem2
      }
    }
    case SETABRIREDICAO: {
      return {
        ...state,
        AbrirEdicao: action.abriredicao
      }
    }
    case SETABRIRAGUARDE: {
      return {
        ...state,
        AbrirAguarde: action.abriraguarde
      }
    }
    case SETATUALIZARCOMPARATIVO: {
      return {
        ...state,
        AtualizarComparativo: action.atualizarcomparativo
      }
    }
    case SETAVISO: {
      return {
        ...state,
        Aviso: action.aviso
      }
    }
    case SETERROGRAVE: {
      return {
        ...state,
        ErroGrave: action.errograve
      }
    }
  }
  return state
}

const rootReducer = combineReducers<AppState>({
  configuracoes: configReducer
})

export const store = createStore(rootReducer)

export const action = (type: any) => store.dispatch({ type })
